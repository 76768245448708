<template>
  <div>
    <validation-observer
      ref='form'
      v-slot='{invalid}'
    >
      <b-card v-if='!isDataLoaded'>
        <div class='text-center text-primary my-2'>
          <b-spinner class='align-middle' />
        </div>
      </b-card>

      <b-form v-else
        novalidate
        @submit.prevent='onSubmit'
      >
        <b-row>
          <b-col
            cols='12'
            xl='9'
            md='8'
          >
            <b-card>
              <b-row>
                <b-col cols='12'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='title'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`title.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        :rules="k === 'en' ? '':'required'"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-input
                            v-model='form.title[k]'
                            :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>

                <b-col
                  cols='12'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='flag'
                    :name="$t('labels.type')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.type')"
                    >
                      <v-select
                        v-model='form.flag'
                        :dir='$store.state.appConfig.layout.direction'
                        label='label'
                        :options='news_types'
                        :reduce='item => item.value'
                        @input='updateSelectedData("flag")'
                        :placeholder="$t('labels.type')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="form.flag === 'championship'"
                  md='6'
                  cols='12'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='championships'
                    :name="$t('labels.select_championship')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.select_championship')"
                    >
                      <v-select
                        v-model='form.championships'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        @input='updateSelectedData()'
                        :options='championships'
                        :reduce='item => item.id'
                        :placeholder="$t('labels.select_championship')"
                        multiple
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="form.flag === 'league'"
                  md='6'
                  cols='12'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='leagues'
                    :name="$t('labels.select_league')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.select_league')"
                    >
                      <v-select
                        v-model='form.leagues'
                        :dir='$store.state.appConfig.layout.direction'
                        label='title_label'
                        :options='leagues'
                        @input='updateSelectedData()'
                        :reduce='item => item.id'
                        :placeholder="$t('labels.select_league')"
                        multiple
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if='teams.length'
                  cols='12'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='teams'
                    :name="$t('labels.teams')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.teams')"
                    >
                      <v-select
                        v-model='form.teams'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='teams'
                        multiple
                        :reduce='item => item.id'
                        :placeholder="$t('labels.teams')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if='players.length'
                  cols='12'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='players'
                    :name="$t('labels.players')"
                  >
                    <b-form-group
                      :label="$t('labels.players')"
                    >
                      <v-select
                        v-model='form.players'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='players'
                        multiple
                        :reduce='item => item.id'
                        :placeholder="$t('labels.players')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols='6'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='embedded_type'
                    :name="$t('labels.embedded_type')"
                  >
                    <b-form-group
                      :label="$t('labels.embedded_type')"
                    >
                      <v-select
                        v-model='form.embedded_type'
                        :dir='$store.state.appConfig.layout.direction'
                        label='label'
                        :options='news_types_embedded'
                        :reduce='item => item.value'
                        :placeholder="$t('labels.type')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
<!--                <b-col cols="6">-->
<!--                  <validation-provider-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="embedded_link"-->
<!--                    :name="$t('labels.embedded_link')"-->
<!--                    rules="url"-->
<!--                  >-->
<!--                    <b-form-group-->
<!--                      :label="$t('labels.embedded_link')"-->
<!--                      label-for="embedded_link"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        id="embedded_link"-->
<!--                        v-model="form.embedded_link"-->
<!--                      />-->
<!--                      <small class="text-danger">{{ errors[0] }}</small>-->

<!--                    </b-form-group>-->
<!--                  </validation-provider>-->
<!--                </b-col>-->

                <b-col cols='12'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='description'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`description.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        :rules="k === 'en' ? '':'required'"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <quill-editor
                            v-model='form.description[k]'
                            :options='snowOption'
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            cols='12'
            xl='3'
            md='4'
          >
            <b-card>
              <b-row>
                <b-col
                  cols='12'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='creatable_id'
                    :name="$t('writer')"
                  >
                    <b-form-group
                      :label="$t('writer')"
                    >
                      <v-select
                        v-model='form.creatable_id'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='writers'
                        :reduce='item => item.id'
                        :placeholder="$t('writer')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols='12'>
                  <validation-provider
                    v-slot='{ errors }'
                    vid='category_id'
                    :name="$t('labels.category')"
                    rules='required'
                  >
                    <b-form-group :label="$t('labels.category')">
                      <v-select
                        v-model='form.category_id'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='categories'
                        :reduce='item => item.id'
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12'>
                  <validation-provider
                    v-slot='{ errors }'
                    vid='tags'
                    :name="$t('labels.tags')"
                    rules='required'
                  >
                    <b-form-group :label="$t('labels.tags')">
                      <v-select
                        v-model='form.tags'
                        :dir='$store.state.appConfig.layout.direction'
                        multiple
                        label='name_label'
                        :options='tags'
                        :reduce='item => item.id'
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12'>
                  <ValidationProvider
                    v-slot='{ errors }'
                    vid='image'
                    :name="$t('labels.image')"
                    rules='required'
                  >
                    <ImagePreview
                      v-model='image'
                      :label="$t('labels.image')"
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-col>
                <b-col cols='12' />
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12'>
                  <div class='d-flex align-items-center justify-content-between'>
                    <b-card-text class='mb-0'>
                      {{ $t('labels.status') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.status'
                      checked='true'
                      value='active'
                      unchecked-value='inactive'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>
                <!--                <b-col cols="12 mt-2">-->
                <!--                  <div class="d-flex align-items-center justify-content-between">-->
                <!--                    <b-card-text class="mb-0">-->
                <!--                      {{ $t('labels.share_to_twitter') }}-->
                <!--                    </b-card-text>-->
                <!--                    <b-form-checkbox-->
                <!--                      v-model="form.share_to_twitter"-->
                <!--                      checked="true"-->
                <!--                      value="1"-->
                <!--                      unchecked-value="0"-->
                <!--                      name="check-button"-->
                <!--                      switch-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <!--                <b-col cols="12 mt-2">-->
                <!--                  <div class="d-flex align-items-center justify-content-between">-->
                <!--                    <b-card-text class="mb-0">-->
                <!--                      {{ $t('labels.share_to_facebook') }}-->
                <!--                    </b-card-text>-->
                <!--                    <b-form-checkbox-->
                <!--                      v-model="form.share_to_facebook"-->
                <!--                      checked="true"-->
                <!--                      value="1"-->
                <!--                      unchecked-value="0"-->
                <!--                      name="check-button"-->
                <!--                      switch-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <b-col cols='12 mt-2'>
                  <div class='d-flex align-items-center justify-content-between'>
                    <b-card-text class='mb-0'>
                      {{ $t('labels.notify_client') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.notify_client'
                      checked='true'
                      value='1'
                      unchecked-value='0'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12 text-center'>
                  <LoadingButton />
                  <b-button
                    type='reset'
                    variant='outline-secondary'
                  >
                    {{ $t('labels.reset') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      tags: [],
      categories: [],
      news_types: [],
      news_types_embedded:[],
      championships: [],
      leagues: [],
      teams: [],
      players: [],
      image: null,
      snowOption: {
        theme: 'snow',
      },
      writers: [],
      form: {
        title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        flag: null,
        teams: null,
        players: null,
        leagues: null,
        championships: null,
        tags: null,
        category_id: null,
        creatable_id: null,
        // share_to_twitter: 0,
        // share_to_facebook: 0,
        notify_client: 0,
        image: null,
        status: 'active',
        embedded_type:null,
        // embedded_link: null,
      },
    }
  },
  watch: {
    'form.championships': function(val) {
      const ids = this.formatUrlLeagueOrChampionship(val, 'championship')
      if (val.length > 0) {
        this.getTeams(`${ids}`)
      } else {
        this.teams = []
      }
    },

    'form.leagues': function(val) {
      const ids = this.formatUrlLeagueOrChampionship(val)
      if (val.length > 0) {
        this.getTeams(`${ids}`)
      } else {
        this.teams = []
      }
    },
    'form.teams': function(val) {
      const ids = this.formatUrlLeagueOrChampionship(val,'team')
      if (val.length > 0) {
        this.getPlayers(`${ids}`)
      } else {
        this.players = []
      }
    },


    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getWriters()
    this.getTags()
    this.getCategories()
    this.getNewsTypes()
    this.getNewsEmbeddedTypes()
    this.getChampionships()
    this.getLeagues()
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true

    }
  },
  methods: {
    getWriters() {
      this.axios.get('/lists/Writer')
        .then(res => {
          this.writers = res.data
        })
    },
    getTags() {
      this.axios.get('/lists/Tag')
        .then(res => {
          this.tags = res.data
        })
    },
    getCategories() {
      this.axios.get('/lists/NewsCategory')
        .then(res => {
          this.categories = res.data
        })
    },
    getNewsTypes() {
      this.axios.get('/lists/news/types')
        .then(res => {
          this.news_types = res.data
        })
    },
    getNewsEmbeddedTypes() {
      this.axios.get('/lists/news/embeddedTypes')
        .then(res => {
          this.news_types_embedded = res.data
        })
    },
    getChampionships() {
      this.axios.get('/lists/Championship')
        .then(res => {
          this.championships = res.data
        })
    },
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },
    getTeams(id) {
      this.axios.get(`/lists/Team?per_page=1${id}`)
        .then(res => {
          this.teams = res.data
        })
    },
    getPlayers(id) {
      id = id.replace(/^&/, "?");

      this.axios.get(`/lists/team/players${id}`)
        .then(res => {
          this.players = res.data

        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = _.pick(data, [
            'title',
            'description',
            'image',
            'status',
            'leagues',
            'championships',
            'flag',
            'creatable_id',
            'share_to_twitter',
            'share_to_facebook',
            'notify_client',
            // 'embedded_link',
            'embedded_type'
          ])
          this.form = {
            ...this.form,
            category_id: data.category_id,
            status: data.status,
            // embedded_link:data.embedded_link,
            embedded_type:data.embedded_type,
            tags: data.tags.map(item => item.id),
            leagues: data.leagues.map(item => item.id),
            championships: data.championships.map(item => item.id),
            teams: data.teams.map(item => item.id),
            players: data.players.map(item => item.id),

          }
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

    formatUrlLeagueOrChampionship(val, type = 'league') {
      let param = ''
      if(type === 'league'){
        param = 'league_ids'
      }
     if(type === 'championship'){
        param = 'championship_ids'

      }
      if(type === 'team'){
        param = 'team_ids'

      }
      let ids = ''
      for (let key = 0; key < val.length; key++) {
        ids += `&${param}[${key}]=${val[key]}`
      }
      return ids
    },

    updateSelectedData(type = null) {
      this.form.teams = null
      this.form.players = null

      if (type === 'flag') {
        this.teams = []
        this.players = []
        this.form.leagues = null
        this.form.championships = null
      }
    },


  },
}
</script>
